import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Radio, RadioGroup, Space } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "radio"
    }}>{`Radio`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Radio, RadioGroup } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=679%3A182'} storybook={'/?path=/story/inputs-radio--radio-group'} vueStorybook={'/?path=/story/inputs-radio--radio-group'} name={'Radio'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Radio — это компонент радиокнопки. Используется для выбора одного варианта из нескольких.`}</p>
    <p>{`Радиокнопки всегда должны находится в группе. В группе должно быть больше одного варианта.
Для создания списков вариантов используйте компонент RadioGroup.`}</p>
    <Playground __position={1} __code={'() => {\n  const [value, setValue] = useState(\'1\')\n  const options = [\n    {\n      label: \'Вариант №1\',\n      value: \'1\',\n    },\n    {\n      label: \'Вариант №2\',\n      value: \'2\',\n    },\n    {\n      label: \'Вариант №3\',\n      value: \'3\',\n    },\n  ]\n  return <RadioGroup options={options} value={value} onChange={setValue} />\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Radio,
      RadioGroup,
      Space,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState('1');
        const options = [{
          label: 'Вариант №1',
          value: '1'
        }, {
          label: 'Вариант №2',
          value: '2'
        }, {
          label: 'Вариант №3',
          value: '3'
        }];
        return <RadioGroup options={options} value={value} onChange={setValue} mdxType="RadioGroup" />;
      }}
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "неактивное-состояние"
    }}>{`Неактивное состояние`}</h3>
    <p>{`Радиокнопку можно сделать неактивной с помощью пропа `}<inlineCode parentName="p">{`inactive`}</inlineCode>{`. В данном состоянии вариант нельзя выбрать`}</p>
    <Playground __position={2} __code={'() => {\n  const [value, setValue] = useState(\'2\')\n  const options = [\n    {\n      label: \'Вариант №1\',\n      value: \'1\',\n      inactive: true,\n    },\n    {\n      label: \'Вариант №2\',\n      value: \'2\',\n    },\n    {\n      label: \'Вариант №3\',\n      value: \'3\',\n    },\n  ]\n  return <RadioGroup options={options} value={value} onChange={setValue} />\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Radio,
      RadioGroup,
      Space,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState('2');
        const options = [{
          label: 'Вариант №1',
          value: '1',
          inactive: true
        }, {
          label: 'Вариант №2',
          value: '2'
        }, {
          label: 'Вариант №3',
          value: '3'
        }];
        return <RadioGroup options={options} value={value} onChange={setValue} mdxType="RadioGroup" />;
      }}
    </Playground>
    <h3 {...{
      "id": "направление-группы-radiogroup"
    }}>{`Направление группы RadioGroup`}</h3>
    <p>{`Можно поменять направление с помощью пропа `}<inlineCode parentName="p">{`direction`}</inlineCode>{`.`}</p>
    <p>{`В качестве значений выберите `}<inlineCode parentName="p">{`vertical`}</inlineCode>{` или `}<inlineCode parentName="p">{`horizontal`}</inlineCode></p>
    <Playground __position={3} __code={'() => {\n  const [value, setValue] = useState(\'2\')\n  const options = [\n    {\n      label: \'Вариант №1\',\n      value: \'1\',\n      inactive: true,\n    },\n    {\n      label: \'Вариант №2\',\n      value: \'2\',\n    },\n    {\n      label: \'Вариант №3\',\n      value: \'3\',\n    },\n  ]\n  return (\n    <div style={{ display: \'flex\' }}>\n      <RadioGroup\n        direction={\'vertical\'}\n        options={options}\n        value={value}\n        onChange={setValue}\n      />\n      <Space size=\"small\" horizontal />\n      <RadioGroup\n        direction={\'horizontal\'}\n        options={options}\n        value={value}\n        onChange={setValue}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Radio,
      RadioGroup,
      Space,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState('2');
        const options = [{
          label: 'Вариант №1',
          value: '1',
          inactive: true
        }, {
          label: 'Вариант №2',
          value: '2'
        }, {
          label: 'Вариант №3',
          value: '3'
        }];
        return <div style={{
          display: 'flex'
        }}>
        <RadioGroup direction={'vertical'} options={options} value={value} onChange={setValue} mdxType="RadioGroup" />
        <Space size='small' horizontal mdxType="Space" />
        <RadioGroup direction={'horizontal'} options={options} value={value} onChange={setValue} mdxType="RadioGroup" />
    </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Radio} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на `}<inlineCode parentName="p">{`input`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "пропы-radiogroup"
    }}>{`Пропы RadioGroup`}</h2>
    <Props of={RadioGroup} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на `}<inlineCode parentName="p">{`Radio`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "css-классы"
    }}>{`CSS классы`}</h2>
    <p>{`Вы можете использовать данный компонент без библиотеки компонентов, а лишь подключив css стили. Ниже приведен пример использования. Компоненты сверстаны по методологии `}<a parentName="p" {...{
        "href": "https://bem.info/"
      }}>{`BEM`}</a>{`: миксуя необходимые модификаторы вы можете задать компоненту определенное состояние.`}</p>
    <p>{`Класс блока для группы: `}<inlineCode parentName="p">{`hse-RadioGroup`}</inlineCode>{`, для элементов группы `}<inlineCode parentName="p">{`hse-Checkbox`}</inlineCode></p>
    <h3 {...{
      "id": "модификаторы"
    }}>{`Модификаторы`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Модификатор`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Описание`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-RadioGroup_vertical`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Вертикальное расположение радио кнопок`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-RadioGroup_horizontal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Горизонтальное расположение радио кнопок`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "пример-использования"
    }}>{`Пример использования`}</h3>
    <Playground __position={6} __code={'<div className=\"hse-RadioGroup hse-RadioGroup_horizontal\">\n  <label className=\"hse-Checkbox hse-Checkbox_checked hse-Checkbox_size_small\">\n    <input\n      type={\'radio\'}\n      className=\"hse-Checkbox__input\"\n      name=\"radio\"\n      value=\"1\"\n    />\n    <div className=\"hse-BaseCheckboxIcon hse-Checkbox__icon hse-BaseCheckboxIcon_checked hse-BaseCheckboxIcon_radio\"></div>\n    <div className=\"hse-Checkbox__label\">Radio 1</div>\n  </label>\n  <label className=\"hse-Checkbox hse-Checkbox_size_small\">\n    <input\n      type={\'radio\'}\n      className=\"hse-Checkbox__input\"\n      name=\"radio\"\n      value=\"2\"\n    />\n    <div className=\"hse-BaseCheckboxIcon hse-Checkbox__icon hse-BaseCheckboxIcon_radio\"></div>\n    <div className=\"hse-Checkbox__label\">Radio 2</div>\n  </label>\n</div>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Radio,
      RadioGroup,
      Space,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div className='hse-RadioGroup hse-RadioGroup_horizontal'>
	    <label className='hse-Checkbox hse-Checkbox_checked hse-Checkbox_size_small'>
	      <input type={'radio'} className='hse-Checkbox__input' name='radio' value='1' />
	      <div className='hse-BaseCheckboxIcon hse-Checkbox__icon hse-BaseCheckboxIcon_checked hse-BaseCheckboxIcon_radio'>
	      </div>
	      <div className='hse-Checkbox__label'>
	        Radio 1
	      </div>
	    </label>
      <label className='hse-Checkbox hse-Checkbox_size_small'>
        <input type={'radio'} className='hse-Checkbox__input' name='radio' value='2' />
        <div className='hse-BaseCheckboxIcon hse-Checkbox__icon hse-BaseCheckboxIcon_radio'>
        </div>
        <div className='hse-Checkbox__label'>
          Radio 2
        </div>
      </label>
    </div>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      